<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div>
          <b-card header-tag="header" footer-tag="footer">
            <b-row slot="header">
              <b-col><h4 class="card-title">Scan {{ scan.name }}</h4></b-col>
              <b-col class="text-right">
                <b-button :to="{ name: 'new-scan' }" variant="primary">Add New</b-button>
              </b-col>
            </b-row>
            <b-card-text>
              <p>{{ this.tests_completed }} / {{ this.tests_to_run }} -----------
                {{ this.scan_status }}</p>
              <b-card :header="'Scan ' + item.name + ' Status: ' + item.status"
                      v-for="(item,index) in scan.groups.data"
                      v-bind:key="'group_' + index"
                      class="mt-4"
              >
                <b-card-text>
                  <table class="table table-striped table-bordered">
                    <thead>
                    <th>#</th>
                    <th>Status</th>
                    <th>Severity</th>
                    <th>Message</th>
                    <th>Solution</th>
                    <th>Rating</th>
                    </thead>
                    <tbody>
                    <tr v-for="(groupItem, itemIndex) in item.items.data"
                        v-bind:key="'group_item_' + itemIndex">
                      <td>{{ itemIndex + 1 }}</td>
                      <td>{{ groupItem.status }}</td>
                      <td>{{ groupItem.severity }}</td>
                      <td>{{ groupItem.message }}</td>
                      <td>{{ groupItem.solution }}</td>
                      <td>{{ groupItem.rating }}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-card-text>
              </b-card>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      scan_status: 'Preparing scan...',
      tests_completed: 0,
      tests_to_run: 0,
    };
  },
  mounted() {
    this.$store.dispatch(window.store.GET_GUEST_SCAN, this.$route.params.id, { root: true })
      .then((response) => {
        if (typeof (window.Echo) !== 'undefined') {
          window.Echo.channel(`laravel_databasescan.${response.data.data.token}`)
            .listen('.scan.started', (e) => {
              this.tests_to_run = e.tests_to_run;
              this.scan_status = 'Starting scan...';
            })
            .listen('.scan.finished', () => {
              this.scan_status = 'Scan Finished';
            })
            .listen('.scan.group.started', (e) => {
              this.scan_status = e.label;
            })
            .listen('.scan.group.finished', (e) => {
              this.tests_completed += 1;
              this.scan_status = e.label;
            })
            .listen('.scan.group.output', () => {
              this.$store.dispatch(window.store.GET_GUEST_SCAN, this.$route.params.id,
                { root: true });
            });
        }
      });
  },
  beforeDestroy() {
    if (typeof (window.Echo) !== 'undefined') {
      window.Echo.leave(`scan.${this.scan.token}`);
    }
  },
  computed: {
    scan() {
      return this.$store.state.scans.scan;
    },
  },
};
</script>
